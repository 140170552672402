import articleComments from "./articleComments";
import appOverrides from "./appOverrides";
import checkWidgetAreaConsent from "./checkWidgetAreaConsent";
import expandTagsInArticle from "./expandTagsInArticle";
import factbox from "./factbox";
import furtherReadingTeasersTracking from "./furtherReadingTeasersTracking";
import lazyLoadAds from "./lazyLoadAds";
import lazyLoadWidgets from "./lazyLoadWidgets";
import liveReportWidget from "@exp/scripts/plugins/live-report-widget";
import newsletterPage from "./newsletterPage";
import podcast from "@exp/scripts/plugins/podcast";
import processHtmlTableWidget from "./processHtmlTableWidget";
import scroll from "@exp/scripts/helpers/scroll";
import slideshow from "@exp/components/slideshow/slideshow.mjs";
import socialMediaEmbeds from "./socialMediaEmbeds";
import socialPanel from "@exp/components/social-panel/social-panel.mjs";
import truncate from "./truncate";
import videoPlayer from "./videoPlayer";
import trackBrandedLinkClick from "@exp/scripts/helpers/tracking/trackBrandedLinkClick";
import { pushArticleScroll } from "@exp/scripts/integration/gtm";
export default function init(article, lazy, observer) {
  videoPlayer(article, lazy);
  slideshow({
    container: "slideshow",
    bulletContainer: "slider__bullets",
    itemContainer: "slider__items",
    controls: "slider__control"
  }, article);
  lazyLoadWidgets({
    selectorClass: "js-widget-area",
    scope: article
  });
  if (lazy) {
    lazyLoadAds(article, lazy);
    newsletterPage(article);
  } else {
    furtherReadingTeasersTracking(article);
  }
  if (article.classList.contains("branded-article")) {
    trackBrandedLinkClick(article);
  }
  if (observer) {
    observer.observe(article);
  }
  if (window.CHANNEL_MOBILE || window.CHANNEL_TABLET) {
    appOverrides(article);
  }
  liveReportWidget(article);
  podcast(article);
  socialMediaEmbeds(article);
  checkWidgetAreaConsent(article);
  socialPanel(article);
  expandTagsInArticle(article);
  processHtmlTableWidget(document);
  articleComments(article);
  truncate(article);
  factbox(article);
  const articleFooter = article.getElementsByClassName("article__footer")[0] || article.getElementsByClassName("recipe-article__bottom")[0];
  if (articleFooter) {
    scroll.add(trackArticleBottomScroll);
  }
  function trackArticleBottomScroll() {
    if (articleFooter.getBoundingClientRect().top >= window.innerHeight) return;
    scroll.remove(trackArticleBottomScroll);
    pushArticleScroll();
  }
}